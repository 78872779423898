.calc-form label,
.calc-form input,
.calc-form select {
    display: block;
    text-align: left;
    width: 100%;
    margin: 10px auto;
    padding: 10px;
    box-sizing: border-box;
    font-size: 1.2em;
}